import { Injectable } from '@angular/core';
import { createClient, Entry } from 'contentful';

const CONFIG = {
  space: '4ld1rkner0dg',
  accessToken: 'QPFMDI1KefQz78W5qGW84Z9HtOYtwXoJ5qf3cYcRHhA',

  contentTypeIds: {
    page: 'page',
    category: 'category',
    product: 'product'
  }
}

@Injectable()
export class ContentfulService {
  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken
  });

  constructor() { }

  async getPage(slug: string): Promise<any> {
    const res = await this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.page
    }, {
      'fields.slug': slug,
      include: 10
    }));
    return res.items ? res.items[0] : [];
  }

  async getCategory(slug: string): Promise<any> {
    const res = await  this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.category
    }, {
      'fields.slug': slug,
      include: 10
    }));
    return res.items ? res.items[0] : [];
  }

  async getCategories(): Promise<Entry<any>[]> {
    const res = await  this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.category
    }, {
      include: 1
    }));
    return res.items ? res.items : [];
  }

  async searchProducts(query: string): Promise<any> {
    const res = await  this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.product
    }, {
      'fields.title[match]': query,
      include: 10,
      limit: 20
    }));
    return res.items ? res.items : [];
  }
}
